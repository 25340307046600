import React from "react";

import amit from "../assets/amit_cup.png";

function About() {
  return (
    <div className="about">
      <h1>About</h1>

      <img src={amit} alt={amit} className="amit-img" />
    </div>
  );
}

export default About;
