import React from "react";

import trophy from "../assets/trophy.png";

function Trophies() {
  return (
    <div className="trophies">
      <h1>Trophies</h1>
      <img src={trophy} alt={trophy} className="trophy-img" />
    </div>
  );
}

export default Trophies;
